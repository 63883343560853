<template>
  <a-modal
    :title="title"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleConfirm"
  >
    <p>{{ text }}</p>
  </a-modal>
</template>

<script>
export default {
  name: 'FollowModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isFollow: {
      type: Boolean,
      default: false
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return this.isFollow ? '取消关注帐号' : '关注帐号'
    },
    text () {
      return this.isFollow ? '是否取消关注该媒体帐号？' : '是否关注该媒体帐号？'
    }
  },
  methods: {
    //
    handleCancel () {
      this.$emit('cancel')
    },
    //
    handleConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="less" scoped>

</style>
