<template>
  <div class="data-table">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :pagination="false"
    >
      <div class="account-info" slot="account" slot-scope="text, record">
        <div class="account-info__avatar">
          <a-avatar
            :src="record.author_url"
            icon="user"
            :size="60"
          />
        </div>
        <div class="account-info__content">
          <p>
            <span>帐号名称：</span>
            <span>{{ record.author_name || '--' }}</span>
          </p>
          <p>
            <span>归属平台：</span>
            <span>{{ record.platform | formatPlatform }}</span>
          </p>
        </div>
      </div>
      <div slot="action" slot-scope="text, record">
        <a-space v-if="record.state === 1">
          <a-button type="link" @click="handleAction('follow', record)">
            取消关注
          </a-button>
          <a-button type="link" @click="handleAction('shield', record)">
            屏蔽
          </a-button>
        </a-space>
        <a-space v-if="record.state === 2">
          <a-button type="link" @click="handleAction('follow', record)">
            关注
          </a-button>
          <a-button type="link" @click="handleAction('shield', record)">
            取消屏蔽
          </a-button>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.page_num"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    type: {
      type: String,
      default: 'follow' // follow or shield
    },
    loading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    pagination: {
      type: Object,
      default () {
        return {
          page_num: 1,
          page_size: 10,
          total: 0
        }
      }
    }
  },
  methods: {
    //
    handlePaginationChange (page_num, page_size) {
      this.$emit('pagination-change', page_num, page_size)
    },
    //
    handleAction (type, record) {
      this.$emit('action', type, record)
    }
  },
  filters: {
    formatPlatform (val) {
      if (val === 1) {
        return '抖音'
      } else if (val === 2) {
        return '快手'
      } else if (val === 3) {
        return '懂车帝'
      } else if (val === 4) {
        return '新片场'
      } else if (val === 5) {
        return '潮点视频'
      } else if (val === 6) {
        return '视觉中国'
      } else if (val === 7) {
        return '爱给网'
      } else {
        return '--'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.account-info {
  display: flex;
  align-items: center;

  &__avatar {
    width: 60px;
  }

  &__content {
    margin: 0 0 0 10px;

    p {
      display: flex;
      margin: 0;
      
      & + p {
        margin-top: 10px;
      }
    }
  }
}

.ant-btn-link {
  padding: 0;
}
</style>
