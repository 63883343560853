export const columns = [
  {
    title: '帐号信息',
    key: 'account',
    align: 'center',
    scopedSlots: { customRender: 'account' }
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    width: 130,
    scopedSlots: { customRender: 'action' }
  }
]
