<template>
  <div>
    <a-tabs v-model="currTab" @change="handleTabsChange">
      <a-tab-pane tab="已关注" key="1">
        <search-form
          :search-form="searchForm"
          @search="handleSearch"
          @reset="handleReset"
        />
        <data-table
          type="follow"
          :loading="loading"
          :columns="columns"
          :data="data"
          :pagination="pagination"
          @pagination-change="handlePaginationChange"
          @action="handleAction"
        />
      </a-tab-pane>
      <a-tab-pane tab="已屏蔽" key="2">
        <search-form
          :search-form="searchForm2"
          @search="handleSearch"
          @reset="handleReset"
        />
        <data-table
          type="shield"
          :loading="loading"
          :columns="columns"
          :data="data2"
          :pagination="pagination2"
          @pagination-change="handlePaginationChange"
          @action="handleAction"
        />
      </a-tab-pane>
    </a-tabs>

    <!-- 关注/取消关注提示弹框 -->
    <follow-modal
      :visible="followVisible"
      :is-follow="isFollow"
      :confirm-loading="confirmLoading"
      @cancel="handleFollowCancel"
      @confirm="handleFollowConfirm"
    />

    <!-- 屏蔽/取消屏蔽提示弹框 -->
    <shield-modal
      :visible="shieldVisible"
      :is-shield="isShield"
      :confirm-loading="confirmLoading"
      @cancel="handleShieldCancel"
      @confirm="handleShieldConfirm"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchForm from './components/SearchForm'
import DataTable from './components/DataTable'
import FollowModal from '../components/FollowModal'
import ShieldModal from '../components/ShieldModal'
import { columns } from './constant'
import creativeApi from '@/api/creativeCollection'

export default {
  name: 'followAccount',
  components: {
    SearchForm,
    DataTable,
    FollowModal,
    ShieldModal
  },
  data () {
    return {
      currTab: '1',
      loading: false,
      columns,
      searchForm: {
        keyword: ''
      },
      data: [],
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      searchForm2: {
        keyword: ''
      },
      data2: [],
      pagination2: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      confirmLoading: false,
      followVisible: false,
      isFollow: false,
      shieldVisible: false,
      isShield: false
    }
  },
  async created () {
    try {
      const { code, data, message } = await this.$api.AppService.getUserInfo()

      if (code === 200) {
        this.$store.commit('SET_USER_INFO', data)
        this.fetchAccountList()
      } else {
        this.$message.error(message)
      }
    } catch (e) {
      console.log(e)
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    // 处理Tabs改变
    handleTabsChange (val) {
      this.fetchAccountList()
    },
    // 处理查询
    handleSearch () {
      if (this.currTab === '1') {
        this.pagination.page_num = 1
        this.fetchAccountList()
      }

      if (this.currTab === '2') {
        this.pagination2.page_num = 1
        this.fetchAccountList()
      }
    },
    // 处理重置
    handleReset () {
      if (this.currTab === '1') {
        this.pagination.page_num = 1
        this.fetchAccountList()
      }

      if (this.currTab === '2') {
        this.pagination2.page_num = 1
        this.fetchAccountList()
      }
    },
    // 处理分页改变
    handlePaginationChange (page_num, page_size) {
      if (this.currTab === '1') {
        this.pagination.page_num = page_num
        this.pagination.page_size = page_size
        this.fetchAccountList()
      }

      if (this.currTab === '2') {
        this.pagination2.page_num = page_num
        this.pagination2.page_size = page_size
        this.fetchAccountList()
      }  
    },
    // 获取帐号列表
    async fetchAccountList() {
      this.loading = true
      try {
        const params = {
          user_id: this.userInfo.id,
          state: this.currTab,
          author_name: this.currTab === '1' ? this.searchForm.keyword : this.searchForm2.keyword,
          page_num: this.currTab === '1' ? this.pagination.page_num : this.pagination2.page_num,
          page_size: this.currTab === '1' ? this.pagination.page_size : this.pagination2.page_size
        }
        const res = await creativeApi.fetchAccountList(params)

        if (res.code === 200) {
          // 已关注
          if (this.currTab === '1') {
            this.data = res.data.list.map(item => {
              return { ...item, is_follow: true, is_shield: false }
            })
            this.pagination.total = res.data.total  
          }

          // 已屏蔽
          if (this.currTab === '2') {
            this.data2 = res.data.list.map(item => {
              return { ...item, is_follow: false, is_shield: true }
            })
            this.pagination2.total = res.data.total
          }
          
          this.loading = false
        } else {
          this.$message.error(res.msg || res.errmsg)
          this.loading = false
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    //
    handleAction (type, record) {
      this.actionType = type
      this.selectedRecord = record

      // 取消关注
      if (this.currTab === '1' && this.actionType === 'follow') {
        this.isFollow = true
        this.followVisible = true  
      }

      // 屏蔽
      if (this.currTab === '1' && this.actionType === 'shield') {
        this.isShield = false
        this.shieldVisible = true
      }

      // 关注
      if (this.currTab === '2' && this.actionType === 'follow') {
        this.isFollow = false
        this.followVisible = true
      }

      // 取消屏蔽
      if (this.currTab === '2' && this.actionType === 'shield') {
        this.isShield = true
        this.shieldVisible = true
      }
    },
    //
    handleFollowConfirm () {
      this.updateFollowAccount()
    },
    //
    handleFollowCancel () {
      this.followVisible = false
    },
    //
    handleShieldConfirm () {
      this.updateFollowAccount()  
    },
    //
    handleShieldCancel () {
      this.shieldVisible = false
    },
    // 更新关注帐号
    async updateFollowAccount () {
      this.confirmLoading = true
      try {
        const params = this.getUpdateParams()
        const res = await creativeApi.updateFollowAccount(params)

        if (res.code === 200) {
          // 取消关注
          if (this.currTab === '1' && this.actionType === 'follow') {
            this.$message.success('取消关注成功')
            this.confirmLoading = false
            this.followVisible = false
            this.fetchAccountList()
          }

          // 屏蔽
          if (this.currTab === '1' && this.actionType === 'shield') {
            this.$message.success('屏蔽成功')
            this.confirmLoading = false
            this.shieldVisible = false
            this.fetchAccountList()
          }

          // 关注
          if (this.currTab === '2' && this.actionType === 'follow') {
            this.$message.success('关注成功')
            this.confirmLoading = false  
            this.followVisible = false
            this.fetchAccountList()
          }

          // 取消屏蔽
          if (this.currTab === '2' && this.actionType === 'shield') {
            this.$message.success('取消屏蔽成功')
            this.confirmLoading = false
            this.shieldVisible = false
            this.fetchAccountList()
          }
        } else {
          this.$message.error(res.msg || res.errmsg)
          this.confirmLoading = false  
        }
      } catch (e) {
        console.log(e)
        this.confirmLoading = false
      }
    },
    getUpdateParams () {
      const { id } = this.selectedRecord
      let params = {}

      // 取消关注
      if (this.currTab === '1' && this.actionType === 'follow') {
        params = { id, is_deleted: 1 }
      }

      // 屏蔽
      if (this.currTab === '1' && this.actionType === 'shield') {
        params = { id, state: 2 }  
      }

      // 关注
      if (this.currTab === '2' && this.actionType === 'follow') {
        params = { id, state: 1 }
      }

      // 取消屏蔽
      if (this.currTab === '2' && this.actionType === 'shield') {
        params = { id, is_deleted: 1 }
      }

      return params
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  margin-bottom: 10px;
}
</style>
