<template>
  <a-modal
    :title="title"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleConfirm"
  >
    <p>{{ text }}</p>
  </a-modal>
</template>

<script>
export default {
  name: 'ShieldModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isShield: {
      type: Boolean,
      default: false
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return this.isShield ? '取消屏蔽帐号' : '屏蔽帐号'
    },
    text () {
      return this.isShield ? '是否取消屏蔽该媒体帐号？' : '是否屏蔽该媒体帐号？'
    }
  },
  methods: {
    //
    handleCancel () {
      this.$emit('cancel')
    },
    //
    handleConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="less" scoped>

</style>
