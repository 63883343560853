<template>
  <a-form-model
    class="search-form"
    ref="searchForm"
    :model="searchForm"
    layout="inline"
  >
    <a-form-model-item label="关键词检索" prop="keyword">
      <a-input
        v-model="searchForm.keyword"
        placeholder="请输入"
        allow-clear
      />
    </a-form-model-item>
    <a-form-model-item>
      <a-space>
        <a-button type="primary" @click="handleSearch">
          查询
        </a-button>
        <a-button @click="handleReset">
          重置
        </a-button>
      </a-space>
    </a-form-model-item>
  </a-form-model>  
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    searchForm: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    //
    handleSearch () {
      this.$emit('search')
    },
    //
    handleReset () {
      this.$refs.searchForm.resetFields()
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
